import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware, { END } from 'redux-saga';
import _ from 'lodash';

import { api } from '../services/api';
import rootReducer from './reducers';
import rootSaga from '../sagas';

const DEFAULT_SCOPES = [
  'layout',
  'env',
  'user'
];

export default function getStore({
  name = '',
  preloadedState = {},
  scope,
  cookieAuth,
}) {
  const sagaMiddleware = createSagaMiddleware();

  if (preloadedState && typeof window !== 'undefined') {
    if (window.__SSR_STORE_STATE__ && window.__SSR_STORE_STATE__[name]) {
      preloadedState = {
        ...preloadedState,
        ...window.__SSR_STORE_STATE__[name],
      };
    } else if (window.defaultState) {
      preloadedState = {
        ...preloadedState,
        ...window.defaultState,
      };
    }
  }

  const store = configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      ..._.pick(rootReducer, scope ? [...scope, ...DEFAULT_SCOPES] : [...DEFAULT_SCOPES])
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware(
        { thunk: { extraArgument: cookieAuth } }
      ).concat([
        sagaMiddleware,
        api.middleware,
      ]),
    devTools: { name },
    ...(preloadedState ? { preloadedState } : {}),
  });

  if (typeof window !== 'undefined' && window.document) {
    sagaMiddleware.run(rootSaga);
  } else {
    store.runSaga = sagaMiddleware.run;
    store.close = () => store.dispatch(END); //eslint-disable-line
  }

  return store;
}
