import React, { useEffect } from 'react';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CssVarsProvider } from '@mui/joy/styles';

import theme from '../helpers/muiTheme';

export function SSRWrapperComponent({ router, children }) {
  let RouterWrapper = ({ children }) => (<>{children}</>);

  if (router) {
    switch (router.type) {
      case 'BrowserRouter':
        RouterWrapper = ({ children }) => (<StaticRouter>{children}</StaticRouter>)
        break;
    }
  }

  return (
    <RouterWrapper>
      <CssVarsProvider theme={theme}>
        {children}
      </CssVarsProvider>
    </RouterWrapper>
  );
}

function HandlerComponents() {
  const { openedModals, notificationPanelOpened } = useSelector((state) => state.layout, shallowEqual);

  useEffect(() => {
    // Prevent background page scrolling when modal is opened
    document.body.classList.toggle('scroll-block', (notificationPanelOpened || openedModals.length > 0));
  }, [openedModals, notificationPanelOpened]);

  return null;
}

export function ClientWrapperComponent({ router, children, name }) {
  let RouterWrapper = ({ children }) => (<>{children}</>);
  const dispatch = useDispatch();

  if (router) {
    switch (router.type) {
      case 'BrowserRouter':
        RouterWrapper = ({ children }) => (<BrowserRouter>{children}</BrowserRouter>)
        break;
    }
  }

  useEffect(() => {
    dispatch({ type: 'CLIENT_HYDRATED', payload: { name } });

    // Dispatch a custom hydration event for Angular initialization
    const event = new CustomEvent(`_CLIENT_HYDRATED_`, { detail: { name }});
    document.dispatchEvent(event);
  }, []);

  return (
    <RouterWrapper>
      <CssVarsProvider theme={theme}>
        {children}
      </CssVarsProvider>
      <HandlerComponents/>
    </RouterWrapper>
  );
}
