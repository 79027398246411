import {
  USER_FETCH_SUCCESS,
  USER_FETCH_ERROR,
  CLIENT_SECRET_FETCH_SUCCESS,
  UPDATE_SUBSCRIPTION_SUCCESS,
} from '../../actions';

const defaultState = { id: null, _meta: null };

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case USER_FETCH_SUCCESS:
      return {
        ...payload,
      };
    case USER_FETCH_ERROR:
      return {
        error: true,
        ...payload,
      };
    case CLIENT_SECRET_FETCH_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
